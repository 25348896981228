@use "./mixins" as *;
@use "./variables" as *;

.navbar-container {
  @include flex(row, center, space-between);
  margin-bottom: 64px;
  flex-wrap: wrap;
  width: 100%;
  position: sticky;
  z-index: 100000;
  top: -16px;
  background-color: transparent;
  transition: background-color .2s;
}
.header-scroll {
  background-color: $PrimaryBlack
}
/* --------   Logo styles --------- */
.coin-logo-container {
  @include flex(row, center, flex-start);
}
.coin-logo {
  width: 100%;
  height: 70%;
}
.coin-image-container {
  @include flex(row, center, flex-start);
  width: 15em;
  margin: 1rem;
  margin-left: 2rem;
}

/* --------   Form Input styles --------- */
.coin-input-container {
  margin: 1rem 2rem;
  @include flex(row, center, flex-start);
}

.coin-input {
  @include flex(row, center, flex-start);
  padding-left: 16px;
  outline: none;
  max-width: 30em;
  width: fit-content;
  flex-grow: 1;
  flex-shrink: calc(12/100);
  height: 42px;
  border-radius: 4px;
  border: none;
  color: $NavbarWhite;
  font-size: 1em;
  font-family: $MainFont;
  background-image: $myGradient;
  &::placeholder {
    color: #333;
  }
}
.coin-input:hover,
.coin-input:focus {
  box-shadow: -3px -5px 10px #31363c, 3px 5px 10px #262a2e;
}
.coin-input::placeholder {
  color: $NavbarWhite;
}

/* --------   Coin Image styles --------- */
.coin__image {
  width: 25px;
  height: 25px;
  margin: auto 1em;
}

/* ----------  Pages styles ------------ */
.coin-pages-container {
  @include flex(row, center, flex-start);
  h3 {
    font-weight: 500;
    font-size: 1em;
    margin: 0 1em;
    color: $QuickSilver;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    &:hover,
    &:focus {
      color: $whiteShade;
      font-weight: 500;
    }
  }
}
.nav-active {
  h3 {
    color: $whiteShade;
    opacity: 1;
    font-weight: 500;
  }
}
