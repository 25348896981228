@use "./mixins" as *;
@use "./variables" as *;

@import "./loadingSpinner";
@import "./coin";
@import "./market";
@import "./feature";
@import "./testimonial";
@import "./coinTable";
@import "./footer";
@import "./home";
@import "./navbar";

// Primary font family - Montserrat
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200&display=swap");
// Coin Card component - IBM Plex Mono
@import url("https://fonts.googleapis.com/css2?family=Hanuman:wght@100;300;400;700;900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/*     ######   Scroll-bar    ######     */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}
*::-webkit-scrollbar {
  width: 5px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}
/*     ######   Scroll-bar    ######     */

body {
  @include flex(column, center, center);
  color: $whiteShade;
  background-color: $PrimaryBlack;
  width: 100vw;
  font-family: $MainFont;
  overflow-x: hidden;
}
.wrapper {
  width: 100vw;
  height: 100vh;
}

.nav-active {
  color: $whiteShade !important;
  opacity: 1;
}

a {
  text-decoration: none;
  outline: none !important;
  color: inherit;
  &:hover {
    color: none;
  }
}

.styled-link,
.priColor-link {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0;
  width: fit-content;
  position: relative;
  white-space: nowrap;
  border: none;
  color: inherit;
  text-decoration: none;
}
.styled-link::after,
.priColor-link::after {
  background-color: #fbf9f9;
  position: absolute;
  bottom: 5px;
  left: 0;
  content: "";
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease 0s;
  width: 100%;
  height: 2px;
}
.styled-link:hover::after,
.priColor-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.priColor-link::after {
  background-color: #d9a6a1;
  width: 100%;
}

.coin-button {
  margin-top: 2rem;
  padding: 1rem;
  border-radius: 4.5px;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: 1.3rem;
  font-weight: 500;
  color: $NavbarWhite;
  background-image: $myGradient;
  cursor: pointer;
  -webkit-box-shadow: 0px 1px 8px 3px rgba(48, 48, 48, 1);
  -moz-box-shadow: 0px 1px 8px 3px rgba(48, 48, 48, 1);
  box-shadow: 0px 1px 8px 3px rgba(48, 48, 48, 1);
}

// MUI override styles
.MuiTableCell-root {
  cursor: context-menu !important;
}
.MuiChip-root {
  font-family: $MainFont !important;
  // background-color: #303030 !important;
}

/* ##############    Mobile screens     ############ */
// @media screen and (min-width: 320px) and (max-width: 767px) {
//   .coin-app {
//     margin: 2rem auto;
//     width: 100%;
//     justify-content: space-evenly;
//   }
//   .coin-input {
//     width: 100%;
//     height: 40px;
//     margin: 0 auto;
//     justify-content: space-evenly;
//   }
// }
/* ##############    iPad     ############ */
// @media screen and (min-width: 768px) and (max-width: 1023px) {
//   .coin-app {
//     /* margin: 5rem 6rem; */
//     width: 100vw;
//   }
// }
/* ##############    iPad pro     ############ */
// @media screen and (min-width: 1024px) and (max-width: 1366px) {
//   .coin-app {
//     margin: 0;
//     /* margin-top: 50px; */
//     max-width: 100vw;
//     width: 100%;
//   }
// }

/* ##############    Large monitors     ############ */
// @media screen and (min-width: 1367px) {
//   .coin-app {
//     margin: 0;
//     max-width: 100vw;
//     width: 100%;
//   }
// }
