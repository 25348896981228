@use "./variables" as *;
@use "./mixins" as *;

// Root level class for the section that wraps all other components
.coin-info-container {
  @include flex(row, flex-start, center);
  flex-wrap: wrap;
  width: 100vw;
  margin-bottom: 2rem;
}
.card-container {
  max-width: 32rem;
  min-width: 20rem;
  width: 100%;
  margin: 0 auto;
}
.card {
  margin: auto;
  max-width: 35rem;
  width: 100%;
  height: fit-content;
  font-family: $CardFont;
  padding: 2em 3em;
  border-radius: 1em;
  border: none;
  background-image: $myGradient;

  .welcome {
    transform: translateZ(20px);
    .image-container {
      @include flex(row, center, space-between);
      padding-bottom: 2em;
      img {
        padding: 0.4em;
        border-radius: 50%;
        border: 1px solid $whiteShade;
      }
      div {
        padding: 0.4rem 0.8rem 0.3rem 0.8rem;
        background-color: transparent;
        color: $whiteShade;
        z-index: 1000;
        opacity: 0.6;
        border: 1px solid $whiteShade;
        outline: none;
        border-radius: 4px;
        font-weight: 300;
        letter-spacing: 1.01px;
      }
    }
  }

  .year {
    font-family: $CardFont;
    font-size: 2.5em;
    .coin-title {
      @include flex(row, center, space-between);
      p,
      div {
        @include flex(row, center, center);
        font-weight: 200;
        font-size: 0.9em;
        margin: 0;
        &:last-of-type {
          width: fit-content;
        }
        .followers {
          font-size: 0.7em;
          margin: 0;
          padding-top: 0.5rem;
          padding-left: 0.5rem;
          text-align: center;
          vertical-align: center;
        }
      }
    }
    .market-info {
      @include flex(row, center, space-between);
      h2 {
        font-weight: 300;
        font-size: 1.2em;
      }
      .price-change {
        p {
          margin: 0;
        }
      }
    }
    span {
      display: block;
      transform: translateZ(40px);
      &:nth-child(2) {
        padding-left: 1em;
        transform: translateZ(60px);
      }
      &:nth-child(3) {
        padding-left: 2em;
        transform: translateZ(80px);
      }
    }
  }
}

.info-container {
  @include flex(column, flex-start, flex-start);
  max-width: 40rem;
  min-width: 25rem;
  width: 100%;
  height: max-content;
  border: 2px solid $GreyBorderColor;
  border-radius: 10px;
  padding: 1rem;
  padding-left: 2rem;
  margin: 0 auto;
  h3 {
    font-size: 2rem;
    font-weight: 600;
  }
  .info-row {
    @include flex(row, center, flex-start);
    margin: 0.5rem 0;
    &:first-of-type {
      margin-top: 2rem;
    }
    p {
      margin: 0;
      margin-right: 1rem;
      width: 7rem;
    }
    .chips-wrapper {
      @include flex(row, center, flex-start);
      flex-wrap: wrap;
    }
    .chip, .website-chip {
      @include flex(row, center, space-between);
      padding: 0.2rem 0.4rem;
      margin: 0 0.2rem;
      border: none;
      outline: none;
      border-radius: 4.55px;
      background-color: $Cultured;
      color: $PrimaryBlack;
      &:first-of-type {
        margin-left: 0;
      }
      a,
      p {
        font-weight: 500;
        padding-left: 0.5rem;
        width: fit-content;
      }
    }
    .website-chip {
      color: $pinkShade;
      padding-right: 1rem;
      background: $GreyBorderColor;
      border: 1px solid $QuickSilver
    }
  }
}

.copy-form {
  @include flex(row, center, space-between);
  width: fit-content;
  .cliptext,
  .submit-button {
    border: none;
    outline: none;
    padding: 0;
    font-family: $MainFont;
    width: fit-content;
    cursor: pointer;
  }
}
