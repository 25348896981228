@use "./mixins" as *;
@use "./variables" as *;

.home-banner {
  @include flex(column, center, center);
    .market-snip {
        max-width: 95vw;
        img {
            width: 100%;
            object-fit: contain;
        }
    }
  .image-container {
    position: absolute;
    top: 0;
    height: 65vh;
    z-index: -1;
    img {
      width: 100vw;
      object-fit: contain;
    }
  }

  .home-typograph {
    @include flex(column, center, center);
    width: 70vw;
    margin: 0 auto;
    flex-wrap: wrap;
    padding: 10rem 0;
    h1 {
      font-size: 4rem;
      width: 100%;
      text-align: center;
      text-shadow: 3px 2px 7px rgba(48, 48, 48, 1);
    }
    p {
      font-size: 1rem;
    }
  }
}
