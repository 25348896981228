$MainFont: "Montserrat", sans-serif;
$CardFont: 'Hanuman', serif;

$whiteShade: #fcfcfc;
$PrimaryBlack: #282828;
$TableBlack: #303030;
$TableHeader: #121212;
$TableMenuBar: #1e1e1e;
$NavbarWhite: #e2e2e2;
$QuickSilver: #A0A0A0;
$QuickSilver2 : #9F9F9F;
$Cultured: #F1F1F1;
$DavysGrey: #4F4F4F;
$MikadoYellow: #FFC800;
$HoneyYellow: #FFAE00;

$pinkShade: #ac32e4;
$violetShade: #7918f2;
$blueShade: #4801ff;
$LightPink: #CA7CEE;

$myGradient: linear-gradient(
    -225deg,
    #ac32e4 0%,
    #7918f2 48%,
    #4801ff 100%
  );

$GreyBorderColor: rgb(47, 51, 54);