@use "./mixins" as *;
@use "./variables" as *;

.feature {
  @include flex(row, center, center);
  max-width: 62rem;
  width: 100%;
  flex-wrap: wrap;
  padding: 2rem;
  .left-container {
    @include flex(column, flex-start, center);
    padding-right: 4rem;
    h3 {
      font-size: 2.5rem;
      padding-bottom: 1rem;
    }
    p {
      font-size: 1.5rem;
      color: $QuickSilver2;
    }
  }
  .right-container {
    @include flex(row, center, center);
    flex-wrap: wrap;
    height: fit-content;
    .grid-container {
      display: block;
      width: 50%;
      height: 18rem;
      padding: 0.5rem;
      .image-box {
        @include flex(row, center, center);
        padding: 1.6rem;
        width: 7rem;
        height: 7rem;
        border-radius: 20px;
        background-image: $myGradient;
      }
      .content-box {
        h4 {
          font-size: 1.4rem;
          font-weight: 500;
          margin: 1rem 0;
        }
        p {
          color: $QuickSilver;
        }
      }
    }
  }
}
