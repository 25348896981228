@use "./mixins" as *;
@use "./variables" as *;

// @include flex(column, flex-start, flex-start);
footer {
  height: fit-content;
  width: 100vw;
  background-color: $TableBlack;
  padding: 3rem 1rem 2rem 1rem;
  .logo-container {
    @include flex(column, flex-start, flex-start);
    padding: 1rem;
    margin-bottom: 1rem;
    max-width: 25rem;
    width: 100%;
    background-color: $PrimaryBlack;
    border-radius: 20px;
    img {
      width: 12rem;
    }
    p {
      color: $Cultured;
      line-height: 20px;
      font-size: 0.8rem;
    }
  }
  ul {
    padding: 0;

    li {
      list-style: none;
      padding: 0.8rem 0;
      width: fit-content;
    }
  }
}

.subscribe-container {
  @include flex(column, flex-start, flex-start);
  p {
    color: $Cultured;
  }
  .footer-form {
    input {
      min-width: 12rem;
      width: 100%;
      margin-top: 2rem;
      padding: 0.6rem;
      border-radius: 4.5px;
      font-weight: 500;
      font-family: $MainFont;
      outline: none;
      border: none;
      margin-bottom: 0.5rem;
      &::placeholder {
        color: $QuickSilver;
      }
    }
    button {
      min-width: 12rem;
      width: 100%;
      background-image: $myGradient;
      font-family: $MainFont;
      color: $Cultured;
      padding: 0.6rem;
      border-radius: 4.5px;
      font-size: 1.1rem;
      font-weight: 500;
      outline: none;
      border: none;
      cursor: pointer;
    }
  }
}

hr {
  background-color: $DavysGrey;
  border: none;
  height: 1px;
  margin-top: 5rem;
}

.copywright-section {
  @include flex(row, center, space-between);
    padding: 2rem;
  p {
    color: $QuickSilver;

  }
}
