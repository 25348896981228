@use "./variables" as *;
@use "./mixins" as *;

// All the Market and USDT Price statistics styles are in this file

.market-container {
  @include flex(column, flex-start, flex-start);
  max-width: 80rem;
  width: 95%;
  height: max-content;
  border: 2px solid $GreyBorderColor;
  border-radius: 10px;
  padding: 1rem;
  margin: 0 auto;
  padding-left: 2rem;
  a {
    text-decoration: underline;
    color: $LightPink;
  }
  h3 {
    font-size: 2rem;
    font-weight: 600;
  }
  .market-row {
    @include flex(row, center, space-between);
    width: 100%;
    border-top: 1px solid $GreyBorderColor;
    padding: 0.5rem 0;
    margin: 0;
    &:first-of-type {
      margin-top: 2rem;
    }
    p {
      margin: 0;
      max-width: max-content;
      width: 100%;
      @include flex(row, center, center);
      span {
        padding-left: 1rem;
        padding-top: 0.2rem;
      }
    }
    a,
    p {
      font-weight: 500;
      padding-left: 0.5rem;
      width: fit-content;
    }
  }
}

.market-row {
  @include flex(row, center, space-between);
  width: 100%;
  border-top: 1px solid $GreyBorderColor;
  padding: 1rem 0;
  margin: 0;
  p {
    margin: 0;
    max-width: max-content;
    width: 100%;
    @include flex(row, center, center);
    span {
      padding-left: 1rem;
      padding-top: 0.2rem;
    }
  }
}

.price-chip-row {
    @include flex(column, center, flex-start);
    width: fit-content;
    span {
        @include flex(row, center, space-between);
        p {
            padding-left: 0.5rem;
        }
    }
    .subtext {
      font-size: 0.7rem;
      padding-top: 0.5rem;
    }
}

.desc-header {
  margin-bottom: 1rem;
  span {
    color: $LightPink;
  }
}